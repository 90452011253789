import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap'
import messagesApi from '../../api/messages.api'
import { toast } from 'react-toastify'
import UsersApi from '../../api/users.api'
import { MessageTypes } from '../../common/enums'

export default class TextAndButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      numbersFileName: '',
    }
    this.SendBulk = this.SendBulk.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }
  async SendBulk() {
    if (this.validateForm()) {
      await messagesApi.sendBulk({
        text: this.props.messageText,
        fileName: this.state.numbersFileName,
        tagLbl: this.props.tagLabel,
        btnText: this.props.buttonText,
        btnUrl: this.props.buttonUrl,
        messageType: MessageTypes.TextButton,
      })
      this.props.resetState()
      toast.success('Пораката беше испратена')
    }
  }

  validateForm() {
    let formValid = true

    if (this.props.messageText == '') {
      this.setState({ remainingCharErr: 'Ве молиме внесете текст' })
      formValid = false
    } else if (this.props.remainingCharacters < 0) {
      this.setState({
        remainingCharErr: 'Максимум број на дозволени карактери е 1000',
      })
      formValid = false
    }

    if (this.state.numbersFileName == '') {
      this.setState({ fileError: 'Ве молиме одберете датотека со броеви' })
      formValid = false
    }
    if (this.props.buttonText == '') {
      this.setState({ buttonTextErr: 'Ве молиме внесете текст за копчето' })
      formValid = false
    }
    if (this.props.buttonUrl == '') {
      this.setState({ buttonUrlErr: 'Ве молиме внесете URL-адреса за копче' })
      formValid = false
    }
    if (this.props.tagLabel == '') {
      this.setState({ tagLabelErr: 'Ве молиме внесете ознака за кампања' })
      formValid = false
    }
    return formValid
  }

  async onChangeHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      )
      if (target.toLowerCase() == 'xlsx') {
        const data = new FormData()
        data.append('file', event.target.files[0])
        var response = await UsersApi.uploadNumbers(data)
        console.log(response)
        this.setState({ numbersFileName: response.data.filename, fileError: '' })
      } else {
        this.setState({
          fileError: 'Неправилен формат на фајл. Форматот мора да биде .xlsx',
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <Col>
        <Form>
          <Form.Group as={Row}>
            <Col sm="10">
              <Col md={12}>
                <Form.Label column sm="6" className="heading bold-text">
                  Внесете текст
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="10"
                  name="messageText"
                  onChange={this.props.handleChange}
                  className="text-area"
                  value={this.props.messageText}
                />
                <Col md={12} className="mt-4">
                  <Row>
                    <Col md={6}>
                      <p className="error">{this.state.remainingCharErr}</p>
                    </Col>
                    <Col md={6}>
                      <label
                        className="col-md-12 mt-2 pull-right"
                        style={{ textAlign: 'right' }}
                      >
                        Преостанати карактери {this.props.remainingCharacters}
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">URL-адреса копче</label>
                <FormControl
                  type="text"
                  placeholder="Внесете URL-адреса на копчето"
                  onChange={this.props.handleChange}
                  name="buttonUrl"
                  value={this.props.buttonUrl}
                ></FormControl>
                <p className="error">{this.state.buttonUrlErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Текст на копче</label>
                <FormControl
                  type="text"
                  placeholder="Внесете текст на копчето"
                  onChange={this.props.handleChange}
                  name="buttonText"
                  value={this.props.buttonText}
                ></FormControl>
                <p className="error">{this.state.buttonTextErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Ознака на кампања</label>
                <FormControl
                  type="text"
                  placeholder="Внесете ознака на кампањата"
                  onChange={this.props.handleChange}
                  name="tagLabel"
                  value={this.props.tagLabel}
                ></FormControl>
                <p className="error">{this.state.tagLabelErr}</p>
              </Col>
              <Col>
                <label className="col-md-12 pl-0 bold-text">
                  Одберeте датотека со броеви
                </label>
                <input
                  type="file"
                  name="numbersFileName"
                  onChange={this.onChangeHandler}
                  value={this.state.selectedFile}
                />
                <p className="error">{this.state.fileError}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <Button
                  onClick={this.SendBulk}
                  sm="3"
                  size="sm"
                  className="margin-top-10px button-standard"
                >
                  Испрати
                </Button>
              </Col>
            </Col>
          </Form.Group>
          <Form.Group as={Row}></Form.Group>
        </Form>
      </Col>
    )
  }
}
